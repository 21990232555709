import React, { useEffect } from 'react';
import { getCurrentLanguage, changeCurrentLanguage } from '../../../config/i18n';
import { localStorageKeys, LocalStorageService, MixpanelService } from "../../../application/services";
import { languageEnum, amenliURLS, cartonaPromo, freeHomeInsurancePromo, insuranceTypeEnum } from '../../../config/enums';
import Reload from '../../../assets/img/reload.svg';
import Arabic from '../../../assets/img/arabic.svg';
import English from '../../../assets/img/english.svg';
import LogoEN from '../../../assets/img/logoEN.svg';
import LogoAR from '../../../assets/img/logoAR.svg';
import cartonaEN from '../../../assets/img/cartona_logo_en.svg';
import cartonaAR from '../../../assets/img/cartona_logo_ar.svg';
import back from '../../../assets/img/arrowLeft.svg';

import { t } from 'i18next';
export function Header({ packagesPage }) {
    const selectedLang = getCurrentLanguage();
    useEffect(() => {
        if (selectedLang === languageEnum.Arabic) {
            document.documentElement.dir = "rtl"
        }
        else {
            document.documentElement.dir = "ltr"
        }

    }, [selectedLang]);
    const changeLanguage = (language) => {
        changeCurrentLanguage(language)
        MixpanelService.getInstance().changeLanguage({ default_language: language, step: window.location.pathname })

    }
    return (
        <div className="container toolbar-padding" >
            <header className="toolbar">
                <nav className="navigation">
                    <div onClick={() => MixpanelService.getInstance().clickAmenliLogo({ step: window.location.pathname })}>
                        <a href={`${amenliURLS.Website}${window.location.search}`} target='_' className='d-flex'>
                            <img src={selectedLang === languageEnum.English ? LogoEN : LogoAR} alt="logo" className="logo" />
                        </a>
                    </div>
                    {window.location.search.includes(cartonaPromo) &&
                        <>
                            <div className='cartona' />
                            <img src={selectedLang === languageEnum.English ? cartonaEN : cartonaAR} alt="cartona_logo" className="logo" />
                        </>
                    }
                    <div className="spacer">
                        {(window.location.search.includes(freeHomeInsurancePromo) || LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                            packagesPage &&
                            LocalStorageService.get(localStorageKeys.insuranceTypeID) === insuranceTypeEnum.MotorInsuranceId &&
                            selectedLang === languageEnum.English ?
                            <div>
                                <div>
                                    <p><span>{t("free")}</span> {t("free_home_insurance")}</p>
                                </div>
                            </div> :
                            (window.location.search.includes(freeHomeInsurancePromo) || LocalStorageService.get(localStorageKeys.promoCode) === freeHomeInsurancePromo) &&
                                packagesPage &&
                                LocalStorageService.get(localStorageKeys.insuranceTypeID) === insuranceTypeEnum.MotorInsuranceId &&
                                selectedLang === languageEnum.Arabic ?
                                <div>
                                    <div>
                                        <p>{t("free_home_insurance")} <span>{t("free")}</span></p>
                                    </div>
                                </div> : null
                        }
                    </div>
                    <div className="navigation-items">
                        <ul>
                            {window.location.pathname.includes('forms') ? null :
                                window.location.pathname.includes('medical-network') || window.location.pathname.includes('provider-details') ?
                                    <li onClick={() => { window.history.back() }}>
                                        <img src={back} alt="reload" width={19} height={20} className='helpIcon' />
                                    </li>
                                    :
                                    <li onClick={() => { window.location.reload(); LocalStorageService.clear(); MixpanelService.getInstance().clickReload({ step: window.location.pathname }) }}>
                                        <a href={`/`}><img src={Reload} alt="reload" width={19} height={20} /></a>
                                    </li>
                            }
                            {selectedLang === languageEnum.English ?
                                <li
                                    onClick={() => changeLanguage(languageEnum.Arabic)} ><img src={Arabic} className='langImg' alt="arabic" width={32} height={13} />
                                </li>
                                : <li
                                    onClick={() => changeLanguage(languageEnum.English)}><img src={English} className='langImg' alt="english" width={47} height={14} />
                                </li>}
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    )
}

