import React from 'react';
import { Drawer, Space } from 'antd';
import FilterNetwork from './filterNetwork';
import { t } from 'i18next'
export const DrawerFilter = ({ open, onClose, access_token, allGovs, toggleAllGovs, selectedCategory, selectCategory, resetFilter,
    setGovernorateValue, governorateValue, setCityValue, cityValue, handleClickSearch
}) => {
    return (
        <Drawer title={t('select_your_search')} onClose={onClose} open={open}
            width={720}
            extra={
                <Space onClick={resetFilter}>
                    <p className='reset'>{t('reset')}</p>
                </Space>
            }
        >
            <FilterNetwork access_token={access_token} allGovs={allGovs} toggleAllGovs={toggleAllGovs}
                selectedCategory={selectedCategory} selectCategory={selectCategory}
                setGovernorateValue={setGovernorateValue} governorateValue={governorateValue}
                setCityValue={setCityValue} cityValue={cityValue} handleClickSearch={handleClickSearch}
            />
        </Drawer>
    );
};
