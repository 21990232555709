import {
    InsuranceService,
    PaymentService,
    PurchaseService,
    LeadsService,
    UtilityService,
    PartnerLeadFormService,
    DeliveryService,
    NetworkService
} from "./services";

export class ServiceProxy {
    InsuranceService;
    PaymentService;
    PurchaseService;
    LeadsService;
    UtilityService;
    PartnerLeadFormService;
    DeliveryService;
    NetworkService;
    constructor(_apiUrl) {
        this._apiUrl = _apiUrl
        this.InsuranceService = new InsuranceService(this._apiUrl);
        this.PaymentService = new PaymentService(this._apiUrl);
        this.PurchaseService = new PurchaseService(this._apiUrl);
        this.LeadsService = new LeadsService(this._apiUrl);
        this.UtilityService = new UtilityService(this._apiUrl);
        this.PartnerLeadFormService = new PartnerLeadFormService(this._apiUrl);
        this.DeliveryService = new DeliveryService(this._apiUrl);
        this.NetworkService = new NetworkService(this._apiUrl);
    }
}