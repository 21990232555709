import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Spinner } from '../../../../components/ui';
import ExpandMore from '../../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../../assets/img/arrow_down_black.svg'
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../../../ServiceProxy';
import { etisalatHost } from '../../../../config/enums';

export const City = ({ governorate, setCityValue, cityValue }) => {
    const { t } = useTranslation();
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ServiceProxy.UtilityService.getCities(governorate.id)
            .then(response => {
                if (response.data) {
                    var cities = response.data
                    setCities(cities)
                    setLoading(false)
                    // const city = LocalStorageService.get(localStorageKeys.city)
                    // setCityValue(city ? cities.find(item => item.id === city) : null)
                }
                else {
                    setLoading(false)
                }
            })
            .catch(function (error) {
                setLoading(false)
                Sentry.captureException(error);
            });

    }, [governorate]);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div>
            {loading ? <Spinner /> :
                <>
                    <div className="mt-30 mb-30" />
                    <Autocomplete
                        options={cities || []}
                        getOptionLabel={(option) => option.name || null}
                        popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}
                        onChange={(event, value) => {
                            setCityValue(value)
                        }}

                        value={cityValue}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                placeholder={t("city_name_placeholder")}
                            />
                        )}
                        classes={{
                            option: 'menuItem'
                        }}
                    />
                </>
            }
        </div>
    )
}
