
import React, { useState, useEffect } from 'react'
import { Spinner, withErrorHandler } from '../../../../components'
import { t } from 'i18next'
import axios from 'axios';
import { UIButton } from '../../../../components/ui/Button';
import { Location } from './location';
import { Categories } from './categories';
import * as Sentry from '@sentry/react';
import { ServiceProxy } from '../../../../ServiceProxy';
const FilterNetwork = ({ access_token, allGovs, toggleAllGovs, selectedCategory,
	selectCategory, resetFilter, setGovernorateValue, governorateValue, setCityValue, cityValue, handleClickSearch }) => {
	const [governorates, setGovernorates] = useState(null)
	const [categories, setCategories] = useState(null)
	const [loading, setLoading] = useState(true)
	const getGovernorates = () => {
		ServiceProxy.UtilityService.getGovernorates()
			.then(response => {
				if (response.data) {
					setGovernorates(response.data)
					listCategories()
				}
				else {
					setLoading(false)
				}
			})
			.catch(function (error) {
				setLoading(false)
				Sentry.captureException(error);
			});
	}
	const listCategories = () => {
		ServiceProxy.NetworkService.getMedicalNetworkCategories(access_token)
			.then(res => {
				if (res && res.data) {
					const result = res.data.data
					// setCity(result.city)
					console.log(res.data)
					setCategories(result)
					setLoading(false)
					// setPolicyData(result)
				}
			})
			.catch(e => {
				setLoading(false)
				Sentry.captureException(e);
			})
	}
	useEffect(() => {
		getGovernorates()

		// eslint-disable-next-line
	}, []);
	return (
		<div className='filter'>
			{!categories || !governorates || loading ? <Spinner /> :
				<>
					<div className='main-layout'>
						<Location className='h-10' governorates={governorates} setGovernorateValue={setGovernorateValue} governorateValue={governorateValue} setCityValue={setCityValue} cityValue={cityValue} />
						<Categories categories={categories} allGovs={allGovs} toggleAllGovs={toggleAllGovs}
							selectedCategory={selectedCategory} selectCategory={selectCategory}

							resetFilter={resetFilter} />
					</div>
					<div className='search-btn-parent'>
						<UIButton title={t('search')} className='search-btn' onClick={handleClickSearch} />
					</div>
				</>}
		</div>
	)
}
export default withErrorHandler(FilterNetwork, axios)