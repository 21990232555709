
import React, { useEffect, useState } from 'react'
import { Button, Overlay, Spinner, withErrorHandler } from '../../components'
import axios from 'axios';
import { PageHeader, InputSearch, ProviderCard, DrawerFilter } from './components';
import { ServiceProxy } from '../../ServiceProxy';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { pageSize } from '../../config/enums';
import FilterIcon from '../../assets/img/filterIcon.svg'
import { t } from 'i18next';
import SearchIcon from '../../assets/img/searchIcon.svg';
import { localStorageKeys, LocalStorageService } from '../../application/services';
const MedicalNetwork = (props) => {
	const [open, setOpen] = useState(false);
	const [location, setLocation] = useState('');
	const [policyData, setPolicyData] = useState(null)
	const { access_token } = useParams();
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [searchParams, setSearchParams] = useState(LocalStorageService.get(localStorageKeys.filterParams) || { page: 1, page_size: pageSize })
	const [allGovs, setAllGovs] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState(LocalStorageService.get(localStorageKeys.filterParams)?.category_id || null);
	const [governorateValue, setGovernorateValue] = useState(LocalStorageService.get(localStorageKeys.filterParams)?.governorate || null);
	const [cityValue, setCityValue] = useState(LocalStorageService.get(localStorageKeys.filterParams)?.city || null);
	const [userLocation, setUserLocation] = useState(null);
	const [results, setResults] = useState(null)
	const [isNextResults, setIsNextResults] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	const toggleAllGovs = () => {
		setAllGovs(!allGovs);
		if (allGovs === false) {
			setGovernorateValue(null)
			setCityValue(null)
			const updatedParams = { ...searchParams, page: 1, lat: null, long: null }
			setSearchParams((prevState) => ({
				...prevState, ...updatedParams
			}));
		}
		if (allGovs === true) {
			const updatedParams = { ...searchParams, page: 1, ...userLocation }
			setSearchParams((prevState) => ({
				...prevState, ...updatedParams
			}));
		}

	};
	const getUserLocation = () => {
		ServiceProxy.UtilityService.getIP()
			.then(res => {
				if (res && res.data) {
					const result = res.data.data
					setLocation(result.city)
					setUserLocation({ lat: result.latitude, long: result.longitude })
					const updatedParams = { ...searchParams, page: 1, lat: result.latitude, long: result.longitude }
					listNetwork(updatedParams)

				}
			})
			.catch(e => {
				Sentry.captureException(e);
			})

	}
	const showPolicyData = () => {
		ServiceProxy.NetworkService.getPolicyData(access_token)
			.then(res => {
				if (res && res.data) {
					const result = res.data.data
					setPolicyData(result)


				}
			})
			.catch(e => {
				Sentry.captureException(e);
			})

	}
	const listNetwork = (params) => {
		ServiceProxy.NetworkService.getMedicalNetwork(access_token, params)
			.then(response => {
				if (response && response.data) {
					setIsNextResults(response.data.next ? true : null)
					setCurrentPage(params.page)
					const result = params.page === 1 ? response.data.results : [...results, ...response.data.results]
					setResults(result)
					setLoading(false)
				}
			})
			.catch(e => {
				Sentry.captureException(e);
			})
	}
	const handleClickProviderCard = (data) => {
		LocalStorageService.set(localStorageKeys.filterParams, searchParams)
		props.history.push({ pathname: `/provider-details`, state: { data, filterParams: searchParams } })
	}
	const onChangeInput = (val) => {
		setName(val)
	}
	const handleSearchByName = () => {

		setLoading(true)
		const updatedParams = { ...searchParams, name, page: 1 }
		listNetwork(updatedParams)
		setSearchParams((prevState) => ({
			...prevState, name, page: 1, filter: false
		}));

	}
	const selectCategory = (id) => {
		setSelectedCategory(id);
	};
	const resetFilter = () => {
		setAllGovs(false)
		setGovernorateValue(null)
		setCityValue(null)
		setSelectedCategory(null)
		LocalStorageService.delete(localStorageKeys.filterParams)
		const updatedParams = {
			name, page: 1, page_size: pageSize
		}

		setSearchParams({
			name, page: 1, page_size: pageSize
		});
		setTimeout(() => {

			listNetwork(updatedParams)
		}, 1000);

	};
	const handleClickSearch = () => {
		onClose()
		setLoading(true)
		var filterParams = searchParams
		if (selectedCategory) {
			filterParams = { ...filterParams, category_id: selectedCategory, page: 1, filter: true }
		}
		if (cityValue) {
			filterParams = { ...filterParams, city_id: cityValue.id, city: cityValue, page: 1, filter: true }
		}
		if (governorateValue) {
			filterParams = { ...filterParams, governorate_id: governorateValue.id, governorate: governorateValue, page: 1, filter: true }
		}
		if (name) {
			filterParams = { ...filterParams, name: name, filter: false }
		}

		setSearchParams(filterParams)
		listNetwork(filterParams)
	}
	const getMoreData = () => {
		if (isNextResults !== null) {
			setLoading(true)
			setCurrentPage(currentPage + 1);
			const updatedParams = { ...searchParams, page: currentPage + 1 }
			listNetwork(updatedParams)
			setSearchParams(updatedParams);
		}
	}
	useEffect(() => {
		window.scrollTo(0, 0)
		if (governorateValue && cityValue) {
			const updatedParams = { ...searchParams, page: 1 }
			listNetwork(updatedParams)
			setSearchParams((prevState) => ({
				...prevState, page: 1
			}));
		}
		else {
			getUserLocation()
		}
		showPolicyData()
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
	return (
		<div>
			{loading && <Overlay />}
			{!policyData || !results ? <Spinner /> :
				<div className="container" id="page-wrapper">
					<div className=" medical-network medical-network-container">
						<DrawerFilter access_token={access_token} showDrawer={showDrawer} open={open} onClose={onClose}
							allGovs={allGovs} toggleAllGovs={toggleAllGovs}
							selectedCategory={selectedCategory} selectCategory={selectCategory}
							setGovernorateValue={(value) => {
								setGovernorateValue(value)
								value ? setAllGovs(false) : setAllGovs(true)
							}} governorateValue={governorateValue}
							setCityValue={(value) => setCityValue(value)} cityValue={cityValue}
							resetFilter={resetFilter} handleClickSearch={handleClickSearch} />
						<PageHeader showDrawer={showDrawer} city={location} />
						<div className="partner mb-3 align-items-center gap-3">
							<InputSearch onChange={onChangeInput} value={name} handleSearch={handleSearchByName} />
							<div className='filter-medical-network'>
								{searchParams.filter && <span />}
								<img src={FilterIcon} style={{ width: 20, height: 25, cursor: 'pointer' }} alt="filter-icon" onClick={showDrawer} />
							</div>
						</div>

						<p>{policyData.issuer_company_name} <span>{`(${policyData.policy_codename})`}</span></p>

						{results.length === 0 ?
							<div className='noResults'>
								<img src={SearchIcon} alt="search" />
								<p>{t("no_results")}</p>
							</div> :
							<div className='partners'>
								{results.map((item, index) => <ProviderCard key={index} data={item} onClick={handleClickProviderCard} />)}
							</div>}
						{isNextResults && <div className='btn'><Button title={t("load_more")} onClick={getMoreData} disabled={loading} /></div>}
					</div>

				</div>}
		</div >
	)
}
export default withErrorHandler(MedicalNetwork, axios)