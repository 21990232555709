import React from "react";
import { TextField } from "../../../components";
import { InputAdornment } from '@mui/material';
import SearchIcon from '../../../assets/img/searchIcon.svg'
import { t } from "i18next";
export const InputSearch = ({ onChange, handleSearch, value }) => {
    return (
        <TextField
            variant="outlined"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleSearch()
                }
            }}
            placeholder={t("search_service_provider")}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start" onClick={handleSearch}>
                        <img src={SearchIcon} style={{ width: 20, height: 20, marginLeft: 14, cursor: 'pointer' }} alt="search-icon" />
                    </InputAdornment>
                ),
            }} />
    )
}