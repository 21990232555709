import axios from "axios";
import { addAxiosDefaultHeaders, removeDefaultHeaders } from "../../helpers";
export class NetworkService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/network`;
    }
    getMedicalNetwork(accessToken, params) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/v1/medical-network-links`, {
            params,
            headers: {
                Authorization: `auth ${accessToken}`,
            }
        });
        addAxiosDefaultHeaders(axios)
        return result

    }
    getMedicalNetworkCategories(accessToken) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/v1/medical-network-links/categories`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
    getPolicyData(accessToken) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/v1/medical-network-links/show`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
}