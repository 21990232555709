import React from 'react';
import { useTranslation } from "react-i18next";
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

import ExpandMore from '../../../../assets/img/arrow_down.svg'
import ExpandMoreBlack from '../../../../assets/img/arrow_down_black.svg'

import { etisalatHost } from '../../../../config/enums';
import { City } from './city';

export const Location = ({ governorates, governorateValue, setGovernorateValue, setCityValue, cityValue }) => {
    const { t } = useTranslation();


    return (
        <div>

            <Autocomplete
                options={governorates || []}
                getOptionLabel={(option) => option.name || null}
                popupIcon={<img src={etisalatHost ? ExpandMoreBlack : ExpandMore} alt="expand" />}
                onChange={(event, value) => {
                    setGovernorateValue(value)
                }}
                value={governorateValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={'outlined'}
                        placeholder={t("governorate_name_placeholder")}
                    />
                )}
                classes={{
                    option: 'menuItem'
                }}
            />
            {governorateValue && <City governorate={governorateValue} setCityValue={setCityValue} cityValue={cityValue} />}

        </div>
    )
}
