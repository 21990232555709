import axios from "axios";
import { removeDefaultHeaders, addAxiosDefaultHeaders } from '../../helpers'
import { staticPageSize } from "../../config/enums";
export class UtilityService {
    constructor(_baseUrl) {
        this._baseUrl = `${_baseUrl}/utility/v1`
    }
    getBrands(params) {
        return axios.get(`${this._baseUrl}/qe/motor_brands`, { params });
    }
    getCarModels(params) {
        return axios.get(`${this._baseUrl}/qe/motor_models`, { params });
    }
    getMotorYears() {
        return axios.get(`${this._baseUrl}/qe/motor_brands/model_years`);
    }
    getGovernorates() {
        return axios.get(`${this._baseUrl}/qe/governorates`);
    }
    getCities(governorate_id) {
        return axios.get(`${this._baseUrl}/qe/cities`, { params: { governorate_id } });
    }
    getIP() {
        return axios.get(`${this._baseUrl}/qe/ip/inquire-geo-data`);

    }
    getDeliveryGovernorates(accessToken) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/delivery/governorates`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            },
            params: { page_size: staticPageSize }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
    getDeliveryCities(accessToken, delivery_governorate_id) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/delivery/cities`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            },
            params: { page_size: staticPageSize, delivery_governorate_id }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
    getDeliveryDistricts(accessToken, delivery_city_id) {
        removeDefaultHeaders(axios)
        const result = axios.get(`${this._baseUrl}/delivery/districts`, {
            headers: {
                Authorization: `auth ${accessToken}`,
            },
            params: { page_size: staticPageSize, delivery_city_id }
        });
        addAxiosDefaultHeaders(axios)
        return result
    }
}
