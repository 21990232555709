import React from "react"
import { t } from "i18next"
export const PageHeader = ({ showDrawer, city }) => {
    return (
        <div className="header-layout">
            <div className="title">
                <h3>{t("service_providers")}</h3>
                <p>{`${t("your_location")} ${city}`}</p>
            </div>
        </div>
    )
}