
import React, { useEffect } from "react";
import { t } from "i18next";
import SearchIcon from '../../../../assets/img/searchIcon.svg';
import MapIcon from '../../../../assets/img/map.svg';
export const ProviderDetails = (props) => {
    const data = props.location.state?.data
    const openLocation = () => {
        const latitude = data.location[1];
        const longitude = data.location[0];
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(googleMapsUrl, "_blank");
    }
    const handleDial = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='provider-details container' id="page-wrapper">
            <div className='header'>
                <h3>{t('servie_provider_details')}</h3>
            </div>
            <div className='card-layout medical-network-container'>
                <div className='info'>
                    <div>
                        <img src={data?.category?.icon ? data.category.icon : SearchIcon} alt="icon" />
                    </div>
                    <div>
                        <h5>{data.name}</h5>
                        <span>{data.category.label}</span>
                    </div>
                </div>
                <div className='divider' />
                <div className='address'>
                    <p>{data.address}</p>
                    <button onClick={openLocation} className="gap-2">
                        <img src={MapIcon} alt="map-img" />
                        {t('location_on_map')}
                    </button>
                </div>
                <div className='divider' />
                <div className='contacts'>
                    <p>{t('contact_service_provider')}</p>
                    <div className='numbers-wrapper'>
                        {data?.phone_numbers.map((number, index) => <div key={index} className='number-card' onClick={() => handleDial(number)}>
                            <span>{number}</span>
                        </div>)}


                    </div>
                </div>
            </div>
        </div>
    )
}