export const validationRules = {
  mobileRegex: "^(\u0660\u0661|01)[0-2,5,\u0665,\u0660-\u0662]{1}[0-9,\u0660-\u0669]{8}",
  nameRegex: "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z -_]*$",
  mobileLength: 11,
  nameMinLength: 4,
  nameMaxLength: 50,
  addressMinLength: 1,
  addressMaxLength: 150,
  builbdingMinLength: 1,
  builbdingMaxLength: 10,
  floorMinLength: 0,
  floorMaxLength: 10000000000,
  ageMaximum: 64,
  priceMinValue: 10000,
  priceMaxValue: 20000000,
  homeValueMin: 500000,
  homeValueMax: 10000000000,
  contentHomeValueMin: 100000,
  contentHomeValueMax: 5000000,
  carMinAge: 18,
  // eslint-disable-next-line no-useless-escape
  URLRegex: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  maxEmployeesNumber: 10000,
  minEmployeesNumber: 5,
  partnerLeadFormNameMinLength: 2
};
