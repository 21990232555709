export const localStorageKeys = {
  quotationSource: "source",
  promoCode: "promo_code",
  insuranceTypeID: "policy_type",
  insuranceType: "insurance_type",
  userName: "name",
  userPhoneNumber: "phone_number",
  userAge: "age",
  userGender: "gender",
  userHasCronicalIllness: "is_chronic_illness",
  lifeInsuranceGoal: "reason",
  motorBrand: "car_brand",
  motorYear: "car_year",
  motorPrice: "car_price_egp",
  country: "destination",
  travelDate: "travel_date",
  returnDate: "return_date",
  passportNumber: "passport_number",
  dateOfBirth: "date_of_birth",
  homeType: "ownership_type",
  homeValue: "home_value",
  contentHomeValue: "content_value",
  city: "city",
  governorate: "governorate",
  governorateID: "governorate_id",
  buildingNumber: "building",
  streetName: "address",
  floorNumber: "floor",
  policyData: "params",
  policyPrice: "policy_price_egp",
  discountedPolicyPrice: "discounted_policy_price_egp",
  monthlyPolicyPrice: "monthly_price_egp",
  packageName: "package_name",
  quotationId: "quotation_id",
  paymentMethod: "payment_method",
  mobileWalletNumber: "mobile_wallet_phone_number",
  src: "src",
  prm: "prm",
  allowDiscountWithInstalment: "allow_discount_with_instalment",
  purchaseToken: "purchase",
  apartment: "apartment",
  app: "is_app",
  motorModel: 'motor_model',
  companyName: 'company_name',
  employeesNumber: "employees_number",
  employeesAge: "employees_age",
  etisalatExternalReference: 'external_reference',
  insuranceCompany: "issuer_company_name",
  paymentType: "payment_type",
  usaCoverage: "is_usa_covered",
  hideBanner: "hide_banner",
  paymentMethodValue: "payment_method_value",
  utmSource: "utm_source",
  campaignUtmSource: "campaignUtmSource",
  recommendationId: "recommendation_id",
  purchaseRef: "purchase_ref",
  carType: "car_type",
  businessInsurance: "business_insurance",
  filterParams: "filter_params"
}

export class LocalStorageService {
  static get = (key) => {
    return JSON.parse(
      sessionStorage.getItem(key) || null
    );
  };

  static set = (key, value) => {
    sessionStorage.setItem(
      key,
      JSON.stringify(value)
    );
  };

  static delete = (key) => {
    sessionStorage.removeItem(key);
  };
  static clear = () => {
    var prm = LocalStorageService.get(localStorageKeys.prm)
    var src = LocalStorageService.get(localStorageKeys.src)
    var quotationSource = LocalStorageService.get(localStorageKeys.quotationSource)
    var promoCode = LocalStorageService.get(localStorageKeys.promoCode)
    var banner = LocalStorageService.get(localStorageKeys.hideBanner)
    var utmSource = LocalStorageService.get(localStorageKeys.utmSource)
    var campaignUtmSource = LocalStorageService.get(localStorageKeys.campaignUtmSource)
    sessionStorage.clear();
    LocalStorageService.set(localStorageKeys.src, src)
    LocalStorageService.set(localStorageKeys.prm, prm)
    LocalStorageService.set(localStorageKeys.quotationSource, quotationSource)
    LocalStorageService.set(localStorageKeys.promoCode, promoCode)
    LocalStorageService.set(localStorageKeys.hideBanner, banner)
    LocalStorageService.set(localStorageKeys.utmSource, utmSource)
    LocalStorageService.set(localStorageKeys.campaignUtmSource, campaignUtmSource)


  }
}

