import React from "react";
import SearchIcon from '../../../assets/img/searchIcon.svg';
export const ProviderCard = ({ onClick, data }) => {
    return (
        <div className='item' onClick={() => onClick(data)}>
            <div>
                <img src={data?.category?.icon ? data.category.icon : SearchIcon} alt="icon" />
            </div>
            <div style={{ flex: 1, width: '73%' }}>
                <p className="title">{data.name}</p>
                <p>{data.category.label}</p>
                <p>{data.address}</p>
            </div>
        </div>
    )
}