import React from "react";
import { t } from "i18next";
export const Categories = ({ categories, allGovs, toggleAllGovs, selectedCategory, selectCategory }) => {
    return (
        <>
            <div className='mt-4'>
                <p>{t('service_provider')}</p>
                <div className='provider-options' >
                    {categories.map(item => <div key={item.id} className={`provider-option ${selectedCategory === item.id ? 'active-provider' : ''}`} onClick={() => selectCategory(item.id)}>{item.label}</div>)}

                </div>
            </div>
            <div>
                <button className={`select-all ${allGovs && 'active'}`} onClick={toggleAllGovs} />
                <span>{t('select_all_goverNorates')}</span>
            </div>
        </>
    )
}